import { createSlice } from "@reduxjs/toolkit";
import * as R from "ramda";

const initialState = {};

const posts = createSlice({
    name: "posts",
    initialState,
    reducers: {
        // Give case reducers meaningful past-tense "event"-style names
        postsAdded(state, action) {
            // @TODO - Why is payload different when querying vs session
            const posts = R.pathOr([], ["payload"], action);

            if (posts) {
                state.posts = posts;
                // @TODO - Move all storage references to hook.
                window.sessionStorage.setItem("posts", JSON.stringify(posts));
            }
        }
    },
});

// `createSlice` automatically generated action creators with these names.
// export them as named exports from this "slice" file
export const { postsAdded } = posts.actions;

// Export the slice reducer as the default export
export default posts.reducer;