import { createSlice } from "@reduxjs/toolkit";
import * as R from "ramda";

const initialState = {};

const pages = createSlice({
    name: "pages",
    initialState,
    reducers: {
        // Give case reducers meaningful past-tense "event"-style names
        pagesAdded(state, action) {
            // @TODO - Why is payload different when querying vs session
            const pages = R.pathOr([], ["payload"], action);

            if (pages) {
                state.pages = pages;
                // @TODO - Move all storage references to hook.
                window.sessionStorage.setItem("pages", JSON.stringify(pages));
            }
        }
    },
});

// `createSlice` automatically generated action creators with these names.
// export them as named exports from this "slice" file
export const { pagesAdded } = pages.actions;

// Export the slice reducer as the default export
export default pages.reducer;