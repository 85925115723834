/* App.js */

/** External Module Dependencies */
import React from "react";
import { Route, Routes, useParams } from "react-router-dom";

import "bootstrap";

/** Global Module Dependencies */
import routes from "./routes";
import "common/styles/css/bootstrap-reboot.css";
import "common/styles/css/bootstrap.css";
import "common/styles/css/bootstrap-grid.css";
import "./style-app.css";

const App = () => {
	const { param } = useParams();

	return (
		<div className="App">
			<div className="main-content">
				<Routes>
					{routes.map((route, idx) => {
						return route.component ? <Route key={idx} path={route.path} exact={route.exact} name={route.name} element={<route.component param={param} />} ></Route> : null;
					})}
				</Routes>
			</div>
		</div>
	);
}

export default App;
