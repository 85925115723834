import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
	baseQuery: fetchBaseQuery({
		// Fill in your own server starting URL here
		baseUrl: "https://backend.melaniemccoy.travel/wp-json/wp/v2/",
	}),
	endpoints: (build) => ({
		// A query endpoint with no arguments
		featuredPosts: build.mutation({
			query: () => "posts?tags=4&_embed",
		}),
		featuredPost: build.mutation({
			query: (id) => "posts/" + id,
		}),
		featuredPages: build.mutation({
			query: () => "pages?_embed&orderby=menu_order",
		}),
	}),
});

export const { useFeaturedPagesMutation, useFeaturedPostMutation, useFeaturedPostsMutation } = api;

// https://backend.melaniemccoy.travel/wp-json/wp/v2/posts?tags=4