import loadable from "@loadable/component";

const Home = loadable(() => import("./components/home/elements/home"));
const Featured = loadable(() => import("./components/featured/elements/featured"));

const routes = [
	{ path: "/", exact: true, name: "home", component: Home, title: "Melanie McCoy Travel - Home" },
	{ path: "/featured/:slug", exact: false, name: "featured", component: Featured, title: "Melanie McCoy Travel - Featured" }
];

export default routes;
